import { useContext, useEffect } from 'react';
import { CourseContext } from '../contexts/course';
import LOADING_STATUS from '../enums/loadingStatus';
import { fetchCourse } from '../services/backend/course';
import useUserHasCourse from './useUserHasCourse';

const useUserCourse = id => {
  const hasCourse = useUserHasCourse(id);
  const {
    course,
    setFetchedCourse,
    status,
    setStatus,
    currentVideo,
    nextVideo,
  } = useContext(CourseContext);

  useEffect(() => {
    if (hasCourse && status === LOADING_STATUS.isIdle) {
      setStatus(LOADING_STATUS.isLoading);
      fetchCourse(id).then(fetchedCourse => {
        setFetchedCourse(fetchedCourse);
        setStatus(LOADING_STATUS.hasSucceeded);
      });
    }
  }, [id, hasCourse, status, setStatus, setFetchedCourse]);

  return {
    course,
    status,
    currentVideo,
    nextVideo,
  };
};

export default useUserCourse;
