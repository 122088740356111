const { cloneDeep } = require('lodash');

/**
 * @param {Array} userProgress
 * @param {string} courseId
 * @returns {Array}
 */
module.exports = (userProgress, courseId) => {
  const updatedProgress = cloneDeep(userProgress);
  return updatedProgress.map(p => {
    if (p.course.toString() === courseId) {
      return {
        course: p.course,
        video: p.video,
        progress: 0,
        finishedCount: p.finishedCount,
        finishedAt: p.finishedAt,
      };
    }
    return p;
  });
};
