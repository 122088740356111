import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import { navigate } from 'gatsby';

import STYLES from '../../enums/styles';
import LOADING_STATUS from '../../enums/loadingStatus';
import { PERMISSIONS } from '../../shared/enums';
import { putPermissions } from '../../services/backend/user';
import { UserContext } from '../../contexts/user';
import useStatus from '../../hooks/useStatus';
import Button from '../clickables/button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
`;

const FinePrinted = styled.p`
  margin-top: auto;
  font-size: ${STYLES.fontSizes.xs};
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    font-style: italic;
  }
`;

const SuccessMessage = styled.p`
  color: ${STYLES.colors.success};
  margin-top: auto;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  margin-left: auto;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    width: 100%;
  }
  button {
    margin-right: 10px;

    @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
      width: 100%;
      margin: 5px 0;
    }
  }
`;

const ButtonForm = styled.form`
  margin-top: 20px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;

  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    width: 100%;
    flex-direction: column;
  }
  button {
    margin-right: 10px;

    @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
      width: 100%;
      margin: 5px 0;
    }
  }
`;

const OfferReminderDialog = ({ closeDialog, includeHoney, courseLink }) => {
  const { wantsReminder, user, setUser } = useContext(UserContext);
  const { isLoading, setStatus } = useStatus();

  const handleReminder = useCallback(
    event => {
      event.preventDefault();
      setStatus(LOADING_STATUS.isLoading);
      const optimisticUser = cloneDeep(user);
      optimisticUser.permissions.push(PERMISSIONS.sendReminder);
      putPermissions(optimisticUser.permissions).then(() => {
        setStatus(LOADING_STATUS.hasSucceeded);
        setUser(optimisticUser);
      });
    },
    [setStatus, setUser, user]
  );

  const handleNoReminder = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  return (
    <Container>
      <h1>Trainings-Erinnerung</h1>
      {wantsReminder ? (
        <SuccessMessage>
          <b>
            Super! Sie haben die Trainingserinnerung aktiviert. Wir schicken
            Ihnen in ein paar Tagen eine Erinnerung.
          </b>
        </SuccessMessage>
      ) : (
        <p>
          <b>Wann darf es weitergehen?</b>
        </p>
      )}
      {!wantsReminder && (
        <p>
          Routine hilft Ihnen Psychologie in den Alltag zu integrieren.
          <br />
          Dürfen wir Sie in ein paar Tagen an das nächstes Training erinnern?
        </p>
      )}
      <FinePrinted>
        Sie können Ihre Präferenzen jederzeit in den
        <a href="/privacy">{' Privatsphäre-Einstellungen '}</a>
        ändern.
      </FinePrinted>
      {wantsReminder ? (
        <ButtonContainer>
          {includeHoney ? (
            <>
              <Button label="Zurück zum Video" onClick={closeDialog} />
              <Button
                label="Zurück zum Seminar"
                onClick={() => navigate(`/${courseLink}`)}
                primary
              />
            </>
          ) : (
            <>
              <Button
                label="Zurück zum Seminar"
                onClick={() => navigate(`/${courseLink}`)}
              />
              <Button label="Zurück zum Video" onClick={closeDialog} primary />
            </>
          )}
        </ButtonContainer>
      ) : (
        <ButtonForm onSubmit={handleReminder}>
          <Button label="Nein danke" onClick={handleNoReminder} />
          <Button label="Erinnere mich" isLoading={isLoading} submit />
        </ButtonForm>
      )}
    </Container>
  );
};

OfferReminderDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  includeHoney: PropTypes.bool.isRequired,
  courseLink: PropTypes.string.isRequired,
};

export default OfferReminderDialog;
