import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/user';

const useUserHasCourse = id => {
  const { user } = useContext(UserContext);
  const [hasCourse, setHasCourse] = useState(
    !!(user && user.courses.find(cid => cid === id))
  );

  useEffect(() => {
    setHasCourse(!!(user && user.courses.find(cid => cid === id)));
  }, [id, user]);

  return hasCourse;
};

export default useUserHasCourse;
