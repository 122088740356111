import React, { useMemo, useCallback, useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import LOADING_STATUS from '../../enums/loadingStatus';
import { addProgress } from '../../shared/actions';
import { putVideoProgress } from '../../services/backend/user';
import {
  setSawReminderPopupFlag,
  getSawReminderPopupFlag,
} from '../../utilities/storage';

import { UserContext } from '../../contexts/user';
import { BrowserContext } from '../../contexts/browser';
import { DialogContext } from '../../contexts/dialog';
import useUserCourse from '../../hooks/useUserCourse';
import useUserHasCourse from '../../hooks/useUserHasCourse';
import useNavigateToLogin from '../../hooks/useNavigateToLogin';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';

import Spinner from '../../components/spinner/spinner';
import VideoPlayer from '../../components/player/player';
import VideOrderDialog from '../../components/dialog/videoOrder';
import OfferReminderDialog from '../../components/dialog/offerReminder';
import Button from '../../components/clickables/button';
import Stack from '../../components/systems/stack';

const tag = 'VideoContainer';

const VideoContainer = ({ courseId, index }) => {
  useNavigateToLogin();
  const { user, setUser, wantsReminder } = useContext(UserContext);
  const { isMobile } = useContext(BrowserContext);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { course, status, currentVideo } = useUserCourse(courseId);
  const isLoggedIn = useIsLoggedIn();
  const userHasCourse = useUserHasCourse(courseId);

  useEffect(() => {
    console.tag(tag).debug('userHasCourse', userHasCourse);
    if (isLoggedIn && !userHasCourse) {
      navigate('/', { replace: true });
    }
  }, [userHasCourse, isLoggedIn]);

  const video = useMemo(() => {
    if (userHasCourse && status === LOADING_STATUS.hasSucceeded) {
      return course.videos.find(v => v.index === index);
    }
    return undefined;
  }, [course, userHasCourse, status, index]);

  const triggerReminderDialog = useCallback(() => {
    const dialog = (
      <OfferReminderDialog
        closeDialog={closeDialog}
        includeHoney={video.trackedProgress === 1}
        courseLink={`/${course.link}`}
      />
    );
    openDialog(dialog);
  }, [openDialog, closeDialog, video, course]);

  const handleVideoEnded = useCallback(() => {
    if (!getSawReminderPopupFlag() && !wantsReminder) {
      // setSawReminderPopupFlag();
      // triggerReminderDialog();
    }
  }, [wantsReminder]);

  useEffect(() => () => closeDialog(), [closeDialog]);

  const handleProgress = useCallback(
    newProgress => {
      const progressPercentage = newProgress > 1 ? 1 : newProgress;
      putVideoProgress(course._id, index, progressPercentage);
      const optimisticUser = cloneDeep(user);
      const progress = addProgress(
        optimisticUser.progress,
        course._id,
        index,
        progressPercentage
      );
      optimisticUser.progress = progress;
      setUser(optimisticUser);
    },
    [course, index, user, setUser]
  );

  if (status !== LOADING_STATUS.hasSucceeded || !course || !currentVideo) {
    console.tag(tag).verbose('page still loading');
    return <Spinner />;
  }
  console.tag(tag).verbose('page loaded, currentVideo', currentVideo);

  return (
    <Stack gap="30px" centered={false}>
      <VideoPlayer
        index={video.index}
        link={video.link}
        onProgress={handleProgress}
        latestProgress={video.trackedProgress}
        widthPercentage={isMobile ? 0.9 : 0.7}
        onVideoEnded={handleVideoEnded}
      />
      <h1>{video.title}</h1>
      <p>
        <i>{video.description}</i>
      </p>
      {false && (
        <>
          <p>{video.reminder}</p>
          <Button
            label="Plane das nächstes Training"
            onClick={triggerReminderDialog}
            primary
          />
        </>
      )}
    </Stack>
  );
};

VideoContainer.propTypes = {
  courseId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default VideoContainer;
