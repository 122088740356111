const { cloneDeep } = require('lodash');

const isOneDayAgo = date => {
  const latestRewatch = new Date(date);
  const now = new Date();
  const difference = now - latestRewatch;
  const differenceInDays = difference / (1000 * 3600 * 24);
  return differenceInDays >= 1;
};

/**
 * @param {Array} userProgress
 * @param {string} courseId
 * @param {string} videoIndex
 * @param {number} percentageViewed
 * @returns {Array}
 */
module.exports = (userProgress, courseId, videoIndex, newProgress) => {
  const updatedProgress = cloneDeep(userProgress);
  const correctedProgress = newProgress >= 0.95 ? 1 : newProgress;
  const lastProgress = updatedProgress.find(
    p => p.course.toString() === courseId && p.video === videoIndex
  );
  if (lastProgress) {
    lastProgress.progress = correctedProgress;
    if (correctedProgress === 1) {
      lastProgress.finishedAt = lastProgress.finishedAt || new Date();
      lastProgress.finishedCount = lastProgress.finishedCount || 1;

      const newRewatch =
        lastProgress.rewatchedAt && isOneDayAgo(lastProgress.rewatchedAt);
      const firstRewatch =
        !lastProgress.rewatchedAt && isOneDayAgo(lastProgress.finishedAt);
      if (newRewatch || firstRewatch) {
        lastProgress.finishedCount += 1;
        lastProgress.rewatchedAt = new Date();
      }
    }
  } else {
    // watching first time
    updatedProgress.push({
      course: courseId,
      video: videoIndex,
      progress: correctedProgress,
      finishedCount: correctedProgress === 1 ? 1 : 0,
      finishedAt: correctedProgress === 1 ? new Date() : undefined,
      rewatchedAt: undefined,
    });
  }

  return updatedProgress;
};
