import React, {
  useState,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import { LOADING_STATUS } from '../enums';

import { UserContext } from './user';

const tag = 'CourseContext';

/**
 * Add user progress to video objects
 * @param {object} user
 * @param {object} course
 */
const buildUserCourse = (user, course) => {
  console.tag(tag).debug('buildUserCourse user is set', !!user);
  console.tag(tag).debug('buildUserCourse course is set', !!course);
  console.tag(tag).debug('user progress', user.progress);
  const userCourse = cloneDeep(course);
  userCourse.videos = course.videos.map(video => {
    const progress = user.progress.find(
      p => p.course === course._id && p.video === video.index
    );
    if (progress) {
      return {
        ...video,
        trackedProgress: progress.progress,
        hasFinished: !!progress.finishedCount,
      };
    }
    return {
      ...video,
      trackedProgress: 0,
      hasFinished: false,
    };
  });
  userCourse.finishedCourse =
    userCourse.videos.filter(v => v.hasFinished).length ===
    course.videos.length;
  return userCourse;
};

// new context
const CourseContext = React.createContext({
  course: undefined,
  setFetchedCourse: () => {},
  status: LOADING_STATUS.isIdle,
  setStatus: () => {},
  currentVideo: undefined,
  nextVideo: undefined,
});

// corresponding provider component
function CourseProvider({ children }) {
  const { user } = useContext(UserContext);
  const [course, setCourse] = useState();
  const [status, setStatus] = useState(LOADING_STATUS.isIdle);

  const setFetchedCourse = useCallback(
    fetchedCourse => {
      console
        .tag(tag)
        .verbose('transforming fetched course to user course with progress...');
      console.tag(tag).debug('fetchedCourse', fetchedCourse);
      const userCourse = buildUserCourse(user, fetchedCourse);
      console.tag(tag).debug('setting course to', userCourse);
      setCourse(userCourse);
    },
    [user]
  );

  useEffect(() => {
    if (user && course) {
      const userCourse = buildUserCourse(user, course);
      setCourse(userCourse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const currentVideo = useMemo(() => {
    if (!course) {
      return undefined;
    }
    const unfinishedVideos = course.videos.filter(v => v.trackedProgress !== 1);
    console.tag(tag).debug('unfinishedVideos', unfinishedVideos);
    return unfinishedVideos.length ? unfinishedVideos[0] : course.videos[0];
  }, [course]);
  console.tag(tag).debug('currentVideo', currentVideo);

  const nextVideo = useMemo(() => {
    if (!currentVideo) {
      return undefined;
    }
    const unfinishedVideos = course.videos.filter(v => v.trackedProgress !== 1);
    return unfinishedVideos.length >= 2 ? unfinishedVideos[1] : undefined;
  }, [course, currentVideo]);
  console.tag(tag).debug('nextVideo', nextVideo);

  const context = {
    course,
    setFetchedCourse,
    status,
    setStatus,
    currentVideo,
    nextVideo,
  };

  return (
    <CourseContext.Provider value={context}>{children}</CourseContext.Provider>
  );
}

CourseProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { CourseContext, CourseProvider };
