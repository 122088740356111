import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import courseType from '../prop-types/course';
import { CourseProvider } from '../contexts/course';
import VideoContainer from '../containers/Video/VideoContainer';

const VideoPage = ({ data: { courses: course }, pageContext: { index } }) => (
  <CourseProvider>
    <VideoContainer courseId={course.id} index={index} />
  </CourseProvider>
);

VideoPage.propTypes = {
  data: PropTypes.shape({
    courses: courseType.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    index: PropTypes.number.isRequired,
  }).isRequired,
};

export default VideoPage;

export const query = graphql`
  query($id: String!) {
    courses(id: { eq: $id }) {
      id
      title
      description
      duration
      imageText
      imageUrl
      previewUrl
      price
      link
      manualUrl
      videos {
        description
        imageUrl
        index
        title
        imageText
        reminder
      }
    }
  }
`;
