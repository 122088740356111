import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import STYLES from '../../enums/styles';
import Button from '../clickables/button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: auto;
  margin-left: auto;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    width: 100%;
  }
  button {
    margin-right: 10px;

    @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
      width: 100%;
      margin: 5px 0;
    }
  }
`;

const VideOrderDialog = ({ courseLink, currentVideoLink, closeDialog }) => {
  const navigateToCourse = useCallback(() => {
    closeDialog();
    navigate(`/${courseLink}`);
  }, [courseLink, closeDialog]);

  const navigateToCurrentVideo = useCallback(() => {
    closeDialog();
    navigate(`/${currentVideoLink}`);
  }, [currentVideoLink, closeDialog]);

  return (
    <Container>
      <h1>Schritt für Schritt</h1>
      <p>
        Sie haben ein Video ausgelassen. Ich lege Wert darauf, dass Sie sich
        kontinuierlich verbessern. Da dies Ihr erster Seminardurchlauf ist,
        sollten Sie die Trainingseinheiten in der richtigen Reihenfolge
        anschauen.
      </p>
      <ButtonContainer>
        <Button label="Zurück zum Seminar" onClick={navigateToCourse} />
        <Button
          label="Zum aktuellen Video"
          onClick={navigateToCurrentVideo}
          primary
        />
      </ButtonContainer>
    </Container>
  );
};

VideOrderDialog.propTypes = {
  courseLink: PropTypes.string.isRequired,
  currentVideoLink: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default VideOrderDialog;
